import React, { Fragment, useState } from "react";
import GiftCatalogService from "../../services/GiftCatalogService";
import swal from "sweetalert";
import "./RedemptionCatalogV2.css";
import { useHistory } from "react-router";

var format = new Intl.NumberFormat("en-IN", {
  minimumFractionDigits: 0,
});

const RedemptionCatalogV2 = ({ data }) => {
  let history = useHistory();
  const [availablePoints, setAvailablePoints] = useState(
    data && data.available_points
  );
  const [sasIncreasePoints, setSasIncreasePoints] = useState();
  const [creditNotePoints, setCreditNotePoints] = useState();
  const [goldVoucherPoints, setGoldVoucherPoints] = useState();
  const [acceptTermsCondition, setAcceptTermsCondition] = useState(false);

  const handleInputChange = (category, e) => {
    if (category === "sas-increase") {
      setSasIncreasePoints(e.target.value);
    } else if (category === "credit-note") {
      setCreditNotePoints(e.target.value);
    } else if (category === "gold-voucher") {
      setGoldVoucherPoints(e.target.value);
    }
  };

  const handleAcceptTermsCondition = () => {
    setAcceptTermsCondition(!acceptTermsCondition);
  };

  const showEmpty = () => {
    return swal({
      title: "",
      text: `Request Submission failed! You have not entered any values. Please enter values and try again`,
      icon: "warning",
      type: "warning",
    }).then((value) => {
    });
  };

  const submitPoints = () => {
    if (!sasIncreasePoints && !goldVoucherPoints && !creditNotePoints) {
      showEmpty();
      return;
    }
    let obj = {
      sas: parseInt(sasIncreasePoints),
      gold: parseInt(goldVoucherPoints),
      credit: parseInt(creditNotePoints),
    };
    GiftCatalogService.placeOrderPoints(obj)
      .then((res) => {
        if (res.data && res.data.message) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success",
          }).then((value) => {
            if (value) {
              setSasIncreasePoints("");
              setGoldVoucherPoints("");
              setCreditNotePoints("");
              setAcceptTermsCondition(false);
              history.push("/");
            }
          });
        }
      })
      .catch((error) => {
        console.log("err", error, error.response);
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.success === false
        ) {
          swal({
            title: "",
            text: error.response.data.error[0].message,
            icon: "warning",
            type: "warning",
          }).then((value) => {
            if (value) {
            }
          });
        }
      });
  };
  return (
    <Fragment>
      <div className="redemption-catalogue-v2-gift-catalogue-wrapper">
        <div className="redemption-catalogue-v2-merchandise-wallet-count-card-redemption card">
          <div className="redemption-catalogue-v2-merchandise-wallet-count-lbl-wrapp">
            <span className="redemption-catalogue-v2-merchandise-text">
              Points Eligible for Redemption
            </span>
            <p className="redemption-catalogue-v2-merchandise-count">
              {format.format(availablePoints && availablePoints)}
            </p>
          </div>
        </div>
        <div className="redemption-catalogue-v2-catalogue-card-container">
          <div className="redemption-catalogue-v2-catalogue-card card">
            <img
              className="redemption-catalogue-v2-catalogue-card-image"
              src={require("../../assets/images/sas.jpg")} alt=""
            />
            <p className="redemption-catalogue-v2-card-heading mb-4">
              {/* SAS Increase */}
              Convert to SAS
            </p>

            {/* <ul className="redemption-catalogue-v2-list-wrapper">
              <li className="redemption-catalogue-v2-card-details">
                100% of point value calculated at 0.40Rs per point would be
                converted into SAS
              </li>
              <li className="redemption-catalogue-v2-card-details">
                5% SAS increase collection discount as June'22 trade offer would
                be applicable*
              </li>
              <li className="redemption-catalogue-v2-card-details">
                1.65% collection discount as per June'22 Trade Offer would be
                applicable*
              </li>
            </ul> */}
            <div
              style={{ textAlign: "center", margin: "0 15px", padding: "10px" }}
            >
              <p className="redemption-catalogue-v2-card-heading">
                {/* Enter Points to convert to SAS Increase */}
                Enter points to convert to SAS
              </p>
              <input
                style={{ marginTop: "5px", margin: "auto" }}
                placeholder="Type Here"
                type="text"
                className="login-input form-control redeptiom-catalog-v2"
                onChange={(e) => handleInputChange("sas-increase", e)}
                value={sasIncreasePoints}
              />
            </div>
          </div>
          <div className="redemption-catalogue-v2-catalogue-card card">
            <img
              className="redemption-catalogue-v2-catalogue-card-image"
              src={require("../../assets/images/credit.jpg")} alt=""
            />
            <p className="redemption-catalogue-v2-card-heading mb-4">
              {/* Credit Note/OAS Reduction */}
              Convert to Credit Note (CN)
            </p>

            {/* <ul className="redemption-catalogue-v2-list-wrapper">
              <li className="redemption-catalogue-v2-card-details">
                100% of point value calculated at 0.40Rs per point
              </li>
              <li className="redemption-catalogue-v2-card-details">
                1.65% collection discount as per June'22 Trade Offer would be
                applicable*
              </li>
            </ul> */}
            <div
              style={{ textAlign: "center", margin: "0 15px", padding: "10px" }}
            >
              <p className="redemption-catalogue-v2-card-heading">
                {/* Enter Points to convert to Credit Note */}
                Enter points to convert to Credit Note
              </p>
              <input
                style={{ marginTop: "5px", margin: "auto" }}
                placeholder="Type Here"
                type="text"
                className="login-input form-control redeptiom-catalog-v2"
                onChange={(e) => handleInputChange("credit-note", e)}
                value={creditNotePoints}
              />
            </div>
          </div>
          <div className="redemption-catalogue-v2-catalogue-card card">
            <img
              className="redemption-catalogue-v2-catalogue-card-image"
              src={require("../../assets/images/gold.jpg")} alt=""
            />
            <p className="redemption-catalogue-v2-card-heading mb-4">
              {/* Gold Voucher */}
              Carry-forward points for the next redemption
            </p>

            {/* <ul className="redemption-catalogue-v2-list-wrapper">
              <li className="redemption-catalogue-v2-card-details">
                Value of voucher as per eligible points and valuation
              </li>
              <li className="redemption-catalogue-v2-card-details">
                Voucher would be of reputed brand with presence across the
                country
              </li>
              <li className="redemption-catalogue-v2-card-details">
                Redemption of vouchers would be at the specified outlets, list
                would be provided in due course
              </li>
              <li className="redemption-catalogue-v2-card-details">
                Terms & Conditions as laid down in the voucher would be
                applicable. Making charges may be applicable
              </li>
            </ul> */}
            <div
              style={{ textAlign: "center", margin: "0 15px", padding: "10px" }}
            >
              <p className="redemption-catalogue-v2-card-heading">
                {/* Enter Points to convert to Gold Voucher */}
                Enter points to convert to carry forward points
              </p>
              <input
                style={{ marginTop: "5px", margin: "auto" }}
                placeholder="Type Here"
                type="text"
                className="login-input form-control redeptiom-catalog-v2"
                onChange={(e) => handleInputChange("gold-voucher", e)}
                value={goldVoucherPoints}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <p className="redemption-catalogue-term-condition-heading">
            Terms & Condition
          </p>
          {/* <ul>
            <li>
              Window for conversion will be open till 20th June 2022. Only
              request received upto 20th June 2022 (conversion window) would be
              considered.
            </li>
            <li>
              1.65% Collection Discount will be posted after completion of
              scheme period.
            </li>
            <li>
              5% Special Discount on SAS increase if Channel Partner retains
              increased SAS till 31st March 2023 ( CN will be posted after 31st
              March 2023)
            </li>
          </ul> */}
          {/* Terms on 10th OCT 24 */}
          <ul>
            <li>The total available points for redemption are mentioned are points accrued till 31st March 2024.</li>
            <li>Dealers can convert Points earn till FY 2023-24 Points into SAS Increase, CN Reduction or Carry-forward points for the next redemption. Option of Point conversion will only applicable for the Points earn till 31st March 2024.</li>
            <li>Dealers can select any one, two or all three options based on their preference.</li>
            <li>If no option is selected by the end of the confirmation window, the default choice will be to carry forward the points for the next redemption. This decision will be considered final and cannot be changed after the confirmation window closes.</li>
            <li>If points entered are more than available points, dealer will not be able to submit redemption request.</li>
            <li>Window to submit Confirmation request is open till __________</li>
            <li>Dealers can submit multiple requests till the window is open.</li>
            <li>Request received last will be considered as the final request from the dealer.</li>
            <li>Request for redemption can be submitted through JK Advantage Web Portal.</li>
            <li>For further assistance, dealers can contact their respective TM/RM or reach out to Advantage Helpdesk on newjkadvantage@trackwalkins.com</li>
          </ul>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              style={{ marginRight: "15px" }}
              type="checkbox"
              onChange={handleAcceptTermsCondition}
              value={acceptTermsCondition}
              checked={acceptTermsCondition}
            />
            <p style={{ marginBottom: "0px" }}>
              I have read the above terms and conditions and agree the same.
            </p>
          </div>
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <button
              disabled={!acceptTermsCondition}
              onClick={() => submitPoints()}
              className="redemption-catalogue-v2-submit"
              style={{ opacity: acceptTermsCondition ? "1" : "0.65" }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Fragment >
  );
};
export default RedemptionCatalogV2;
